import React, { Component } from 'react'
import './App.css'

class App extends Component {
  render() {
    return (
      <>
        <header className='App-header'>
          <ul className='link'>
            <li>
              <div className='navLink'>
                In art and dream may you proceed with abandon. In life may you
                proceed with balance and stealth. - Patti Smith
              </div>
            </li>
          </ul>
        </header>
        <div className='App'>
          <div className='content'>
            <div className='page'>
              <h2>Ariadna E. Norberg</h2>
              <h5>Software Seamstress</h5>
              <p className='center'>
                I am a fullstack software engineer at Discuss.io.
              </p>
              <p className='divider'>●</p>
              <p>
                In between work I indulge in the arts, food, and literature.
              </p>
            </div>
          </div>
        </div>
        <footer className='App-footer'>
          <ul className='link'>
            <li>
              <a
                className='navLink'
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.linkedin.com/in/ariadna-norberg'
              >
                linkedin
              </a>
            </li>
          </ul>
        </footer>
      </>
    )
  }
}

export default App
